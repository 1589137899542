<template>
    <b-card class="mt-5" no-body>
        <template v-if="title || true" #header>
            <h4>
                <span>{{ title }}</span> <i @click="toggleFullScreen" class="fa fa-expand-alt"></i>
            </h4>
        </template>
        <div ref="mapWidget" id="mapWidget" style="width: 100%; height: 700px"></div>
    </b-card>
</template>

<script>
import L from "leaflet/dist/leaflet"
import "leaflet/dist/leaflet.css"

import "leaflet-draw"
import "leaflet-draw/dist/leaflet.draw.css"
import { DEFAULT_MAP_CENTER } from "@Platon/const"
import LeafletDrawUz from "@Platon/external/map/DrawingToolbarUz.js"
import { Color } from "highcharts"

export default {
    name: "WidgetInfoMap",

    props: {
        options: {
            required: true
        }
    },

    data() {
        return {
            fullScreen: false,
            infoControl: null
        }
    },

    created() {
        L.drawLocal = LeafletDrawUz
    },

    mounted() {
        this.renderMap()
    },

    methods: {
        renderMap() {
            this.setupMap()
            this.initInfoContol()
            this.renderPolygonFromInput()
        },

        getMapCenter() {
            return new L.LatLng(...this.mapCenter)
        },

        getMapZoom() {
            let zoom = Number(this.defaultMapZoom)

            if (Number.isInteger(zoom)) {
                return zoom
            }

            return 13
        },

        getColor(value) {
            let data = (this.options.polygons || []).map((o) => o[this.options.valueField])

            let max = Math.max(...data)
            let min = Math.min(...data)

            return Color.parse(this.colors[0]).tweenTo(Color.parse(this.colors[1]), /* 0-1 */ value / (max - min))
        },

        renderPolygonFromJson(geoJson) {
            let json = new L.GeoJSON(geoJson, {
                onEachFeature: (feature, layer) => {
                    layer.addTo(this.drawingLayer)
                    let seeArea = (L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 10000).toFixed(2)

                    layer.on({
                        mouseover: (e) => {
                            var layer = e.target
                            layer._map.infoControl.updateInfo(layer.feature.properties)
                            layer.setStyle({ weight: 2, fillOpacity: 0.7 })
                            if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                                layer.bringToFront()
                            }
                        },
                        mouseout: (e) => {
                            var layer = e.target
                            layer._map.infoControl.updateInfo()
                            layer.setStyle({ weight: 1, fillOpacity: 0.5 })
                            if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                                layer.bringToFront()
                            }
                        }
                    })
                },
                style: (feature) => {
                    return {
                        fillColor: this.getColor(feature.properties[this.options.valueField]),
                        weight: 1,
                        opacity: 1,
                        color: this.getColor(feature.properties[this.options.valueField] * 1.1),
                        fillOpacity: 0.5
                    }
                }
            })
            json.infoControl = this.infoControl
            this.map.fitBounds(this.drawingLayer.getBounds())
        },

        initInfoContol() {
            this.infoControl = L.control({ position: "bottomright" })
            this.infoControl._div = L.DomUtil.create("div", "areaInfo")
            this.infoControl.onAdd = function (map) {
                this.updateInfo()
                return this._div
            }

            this.infoControl.updateInfo = function (props) {
                let html = ""
                if (props) {
                    html = '<div class="dataHolder"><h4>Малумотлар</h4>'
                    let field
                    for (field in props) {
                        html += "<h6><b>" + field + "</b>: " + props[field] + "</h6>"
                    }
                    html += "<div>"
                }

                this._div.innerHTML = html
            }
            this.map.infoControl = this.infoControl
            this.map.addControl(this.infoControl)

            var legend = L.control({ position: "bottomleft" })
            legend.onAdd = (map) => {
                let div = L.DomUtil.create("div", "infoLegend")
                let i = 0
                for (i = 0; i < [1, 3, 4].length; i++) {
                    div.innerHTML +=
                        '<i style="background:' + this.getColor(i) + '"></i> ' + i + (i ? "&ndash;" + i + "<br>" : "+")
                }

                return div
            }
            this.map.addControl(legend)
        },

        setupMap() {
            this.map = new L.Map("mapWidget", { center: this.getMapCenter(), zoom: this.getMapZoom() })
            this.drawingLayer = L.featureGroup().addTo(this.map)
            let googleSat = L.tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
                maxZoom: 20,
                subdomains: ["mt0", "mt1", "mt2", "mt3"],
                attribution: "google"
            })
            let googleStreets = L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
                maxZoom: 20,
                subdomains: ["mt0", "mt1", "mt2", "mt3"],
                attribution: "google"
            })

            L.control
                .layers(
                    {
                        googleSat: googleSat.addTo(this.map),
                        googleStreets
                    },
                    { [this.$l("platon.mappolygon.drawinglayer", "Чизмалар")]: this.drawingLayer },
                    { position: "bottomright", collapsed: false }
                )
                .addTo(this.map)
        },

        renderPolygonFromInput() {
            let polygonData = this.options.polygons || []

            let featureCollection = polygonData.map((polygon) => {
                let properties = {}
                let field
                for (field in polygon) {
                    if (field !== "geo_polygon") {
                        properties[field] = polygon[field]
                    }
                }
                return { type: "Feature", properties, geometry: JSON.parse(polygon.geo_polygon.value) }
            })

            let geoJSON = { type: "FeatureCollection", features: featureCollection }

            this.drawingLayer.clearLayers()
            this.renderPolygonFromJson(geoJSON)
        },

        toggleFullScreen() {
            if (!document.fullscreenElement && this.fullScreen) {
                this.fullScreen = false
            }

            if (this.fullScreen) {
                document.exitFullscreen()
            } else {
                this.$refs.mapWidget.requestFullscreen()
            }

            this.fullScreen = !this.fullScreen
        }
    },

    computed: {
        title() {
            return this.options.title || ""
        },

        mapCenter() {
            return this.options.mapCenter || DEFAULT_MAP_CENTER
        },

        defaultMapZoom() {
            return this.options.mapZoom || 11
        },

        colors() {
            return this.options.colors || ["#fff", "#00f"]
        }
    }
}
</script>

<style>
.areaInfo {
    background: white;
    padding: 16px;
}
</style>
